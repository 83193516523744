import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Foodstory Cafe Customer Story - Rotaready"
          description="Find out how Foodstory Cafe have reduced time spent on payroll and HR related tasks by 2 days every month."
          url="customer-stories/hospitality-foodstory-cafe"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Plate of Mexican food"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Foostory Cafe logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Find out how Foodstory Cafe have reduced time spent on payroll and HR related tasks by 2 days every month." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="2" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="26" />
              <StatSubtitle text="staff" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Foodstory Cafe" />
              </Content>

              <Content>
                <TextBlock text="With two sites and a team of just under 30, Aberdeen based Foodstory Cafe is in the midst of a great journey. Since day one owners Lara & Sandy have worked passionately to grow a team who share not only their love of food and great coffee, but also the joy of bringing people together." />
              </Content>

              <Content>
                <TextBlock text="The cafe owners don't underestimate the importance of looking after their team. Part of this has been giving them the tools to make their lives easier and provide them with greater visibility and control." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Looking for more than just staff scheduling" />
              </Content>

              <Content>
                <TextBlock text="The dated system being used by Foodstory to schedule staff time was no longer up to the high standards the business set for the software it uses. With limited features and a dated user interface, Foodstory decided it was time to move to another solution. One that would give the team and the wider business the best tool for the job." />
              </Content>

              <Content>
                <TextBlock>
                  It was about more than just staff scheduling. The cafe owners were looking for a solution to help them solve a number of challenges. They wanted the ability to monitor staff time with greater accuracy; a tool that would give their team more accountability when it came to clocking in and out; one central place for employee documentation; and last but not least... a solution that would save them time on monotonous tasks such as <Link style="color:inherit;" to="/payroll">payroll</Link>.
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="Upon a recommendation from one of Rotaready's clients BrewDog, Foodstory looked to the workforce management experts to help take its attendance monitoring and cost control to the next level." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Onwards and upwards" />
              </Content>

              <Content>
                <TextBlock text="After just one month, which included training all staff and rolling out the solution across the business, the Foodstory team were fully up and running with Rotaready." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready may be based in London but their level of customer service is amazing. They answer the phone in seconds and will solve any issue during the phone call."
                  attribution="Sandy McKinnon - Co-owner"
                />
              </Content>

              <Content>
                <TextBlock text="Since getting started with Rotaready in 2017 and using the majority of the features available to them; the business have seen numerous improvements. One of the key benefits seen by the Foodstory team is a significant reduction in time spent on laborious administrative tasks." />
              </Content>
            </Section>

            <Section>
              <PopQuote text="The ability to store and access all important employee documentation in one place and being able to instantly pull accurate timesheets to inform payroll, has freed up a lot of time for the team. In fact, it saves the cafe 2 days on payroll every month." />
            </Section>

            <Section>
              <Content>
                <Blockquote attribution="Sandy McKinnon - Co-owner">
                  Rotaready has improved our <Link to='/payroll' style="color: inherit;">payroll</Link> and HR services and free us up an average of 2 days a month which would have been spent on our staff time-sheets, <Link to='/payroll' style="color: inherit;">payroll</Link> and HR needs in the past.
                </Blockquote>
              </Content>

              <Content>
                <TextBlock text="The benefits for Foodstory didn't stop at time saving. Storing employee documents and their expiry dates has reduced the risk of compliance issues." />
              </Content>

              <Content>
                <TextBlock>
                  And then there's Rotaready's <Link style="color:inherit;" to="/time-attendance">time and attendance</Link> feature, one of Foodstory's favourites. Not only does it give staff the ability to easily clock in and out using the tablet onsite or via the mobile app, it means owners Lara & Sandy are able to monitor staff time and costs more accurately. As a result, they can ensure their employees are being paid for exactly the hours they've worked.
                </TextBlock>
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="What's next?" />
              </Content>

              <Content>
                <TextBlock text="Rotaready's customer success team are continuing to work closely with Foodstory to help them leverage all the benefits of the platform, while feeding back to the engineering team to drive the product forwards. Next on the cards for Foodstory is rolling out mobile based clocking in and out across the business and enabling an integration with Foodstory's till system." />
              </Content>

              <Content>
                <Blockquote attribution="Sandy McKinnon - Co-owner">
                  After using Rotaready for the past 2 years we cannot imagine what we ever did before. It's a seamless system which meets all of our payroll and HR needs as well as staff time keeping. It has sped up our <Link to='/payroll' style="color: inherit;">payroll</Link> significantly and if the Rotaready team continue to develop their technology in the way they have since we started working with them, we can see ourselves working with them for years to come.
                </Blockquote>
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline.childImageSharp.gatsbyImageData}
                alt="Barista behind a coffee machine"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_foodstorycafe"
                label="rotaready.com/customer-stories/hospitality-foodstory-cafe"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Foodstory.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-foodstory.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-foodstory.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline: file(relativePath: { eq: "customer-stories/inline-foodstory.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
